

@media only screen and (min-width:375px){
    .heading{

        font-size:x-large
    }

}

@media only screen and (min-width:992px){
    .heading{

        font-size:xx-large
    }

} 
