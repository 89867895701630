::-webkit-scrollbar {
    width: 10px
}

::-webkit-scrollbar-track {
    background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
    background-color: #b8b8b8;
    border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover{
    background-color: #b9b9b9;
}